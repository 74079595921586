import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyBGQM3feYNnvGI-pNH7zxWLd23bnJ59x9g",
  authDomain: "rtr-website-23f62.firebaseapp.com",
  projectId: "rtr-website-23f62",
  storageBucket: "rtr-website-23f62.appspot.com",
  messagingSenderId: "582281957676",
  appId: "1:582281957676:web:06130f0937e623dbcd5c89",
  measurementId: "G-E8DGJKN4JG"
};

const fire = initializeApp(firebaseConfig); 
const database = getFirestore(fire);

export default database;

