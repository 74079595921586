/* eslint-disable react/style-prop-object */
import React from 'react'
import Banner from './Banner/Banner';
import './Home.css';
import NumBoard from './NumBoard/NumBoard';
import Sponsors from './Sponsors/Sponsors';
import StageInfo from './StageInfo/StageInfo';
import Stages from './Stages/Stages';
import Testimonials from './Testimonials/Testimonials';
import { NavLinks} from '../Navbar/NavElements';

const Home = () => {
    return (
        <>
        <img src='images/bg_top.svg' alt='Bottom Vector' className='top--vector'></img>
        <Banner/>
        <StageInfo/>
        <Stages/>
        <div className="about-us">
            <div className="about--img">
                <img src='images/trophy.jpg' alt='big'></img>
                {/* <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/2015%2FAward2015.JPG?alt=media&token=eea87a40-07f9-4e6e-ab52-e8fdb132a38d' alt='big'></img> */}
            </div>
            <div className="about--data">
                <p className='about--topline'>ABOUT US</p>
                <p className='about--headline'>A Place To Rediscover Your Technical Skills</p>
                <p className='about--para'>Robotics being a multi disciplinary field we have students from all departments working in Unison to manufacture the Robots. Our open culture and hands on facility gives students the platform to increase their skillset professionally and to share these experiences with everyone.</p>

                <span><a href='/history'><i className='about--clickable'>Check Our Events</i></a></span>
            </div>
        </div>
        <NumBoard/>
        <Testimonials className='test_x' />
        <Sponsors/>
        <img src='images/nnew.svg' alt='Bottom Vector' className='bottom--vector'></img>

        <img src='images/nnew.svg' alt='Bottom Vector' className='bottom--vector2'></img>
        </>
    )
}

export default Home;
