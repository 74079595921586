import React from 'react';
import './Admin.css';

const Admin = () => {
  return(
      <>
      Welcome Admin
      </>
  )
};

export default Admin;
