import {React, useState, useEffect} from 'react';
import database from '../../fire';
import { addDoc, collection, doc, getDoc } from "firebase/firestore";

import './Blog.css';


const Blog = () => {
    const docRef = doc(database, "Pages", "Home");

    // useEffect(() => {
    //     getDoc(docRef).then(res =>{
    //         if(res.exists()){
    //             console.log("Document data:", res.data());
    //         } else console.log("No such document!");
    //     });
    // }, []);

    // const handleClick = () =>{
    //     addDoc(collection(database, 'Pages'), {
    //         test: "Data Test A",
    //         testB: "55"
    //     }).then(res =>{
    //         console.log("Doc ID: ", res.id);
    //     })
    // }
    return (
        <>
        Hello World
        </>
    )
}

export default Blog;
