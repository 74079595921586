import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Footer.css';

const Footer = () => {
  return (
    <>
    <footer>
        <div class="footer-container">
            <div class="footer-item">
                <h3>Contact Us</h3>
                <p>MMCOE</p>
                <p>Karvenagar, Pune</p>
                <p><a href="mailto:robocon_teamrudra@mmcoe.edu.in" target="_blank" rel="noreferrer">Email Us: robocon_teamrudra@mmcoe.edu.in</a></p>
            </div>
            <div class="footer-item">
                <h3>Instagram</h3>
                <p>Follow us on Instagram for updates and behind-the-scenes looks at our work!</p>
                <a href="https://www.instagram.com/robocon_teamrudra/" target="_blank" rel="noreferrer">Follow us</a>
            </div>
            <div class="footer-item">
                <h3>LinkedIn</h3>
                <p>Connect with us on LinkedIn to stay up-to-date on our latest projects.</p>
                <a href="https://www.linkedin.com/company/robocon-team-rudra/mycompany/" target="_blank" rel="noreferrer">Connect with us</a>
            </div>
        </div>
    </footer>
    </>

  )
}

export default Footer