import React, { useState, useEffect } from "react";
import UserAuth from "../UserAuth/UserAuth";
import database from "../../fire";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import SuperAdmin from "../SuperAdmin/SuperAdmin";
import Admin from "../Admin/Admin";
import Creator from "../Creator/Creator";

function SignIn() {
	const [user, setUser] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [emailError, setEmailError] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [name, setName] = useState("");
	const [number, setNumber] = useState("");
	const [hasAccount, setHasAccount] = useState("");
	const [nameError, setNameError] = useState("");
    const [isAdmin, setAdmin] = useState(false);
    const [isSuperAdmin, setSuperAdmin] = useState(false);
    const [isCreator, setCreator] = useState(false);

    const auth = getAuth();

	const clearErrors = () => {
		setEmailError("");
		setPasswordError("");
	};

	const handleLogin = () => {
		clearErrors();
        signInWithEmailAndPassword(auth, email, password).then((userCreds) =>{
            setUser(userCreds.user);
        })
        .catch((err) =>{
            switch (err.code) {
                case "auth/invalid-email":
                case "auth/user-disabled":
                case "auth/user-not-found":
                    setEmailError(err.message);
                    break;
                case "auth/wrong-password":
                    setPasswordError(err.message);
                    break;
                default:
                    break;
            }
        })
	};
	const handleLogout = () => {
        signOut(auth);
        setAdmin(false);
        setCreator(false);
        setSuperAdmin(false);
	};

	const handleSignUp = () => {
		clearErrors();
		if (
			(name !== "") &
			(number !== "") &
			(email !== "") &
			(password !== "")
		) {
			// fire.auth()
			// 	.createUserWithEmailAndPassword(email, password)
			// 	.then(function(data){
			// 		var UID = data.user.uid;					
			// 		initialDocs(UID);
			// 		console.log(UID);
			// 	})
			// 	.catch((err) => {
			// 		switch (err.code) {
			// 			case "auth/email-already-in-use":
			// 			case "auth/invalid-email":
			// 				setEmailError(err.message);
			// 				break;
			// 			case "auth/weak-password":
			// 				setPasswordError(err.message);
			// 				break;
			// 			default:
			// 				break;
			// 		}
			// 	});
		} else {
			setEmailError("Please fill out all the fields");
			setNameError("Please fill out all the fields");
		}
	};

	const authListener = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
			  setEmail('');
			  setPassword('');
              setUser(user);
              const docRef = doc(database, "users", user.uid);
              getDoc(docRef).then((res) =>{
                if(res.exists()){
                    if(res.data().role !== 'Super'){
                        if(res.data().role === 'Admin'){
                            setAdmin(true);
                        } else if(res.data().role === 'Creator'){
                            setCreator(true);
                        }
                    } else setSuperAdmin(true);
                } else console.log("User Doesn't exist");
              });
            } else {
                setUser("");
            }
          });
	};

	useEffect(() => {
		authListener();
	});

	return (
		<>
            {/* {isSuperAdmin ? (
			<SuperAdmin
			 handleLogout={handleLogout}
			 handleSignUp={handleSignUp}
			 email={email}
			 setEmail={setEmail}
			 password={password}
			 setPassword={setPassword}
			 emailError={emailError}
			 passwordError={passwordError}/>) : (<></>)}
            {isAdmin ? (<Admin/>) : (<></>)}
            {isCreator ? (<Creator/>) : (<></>)} */}
			{user ? (
				<>
					{isSuperAdmin ? (
					<SuperAdmin
						handleLogout={handleLogout}/>) : (
						<>
						{isAdmin ? (<Admin/>):(<Creator/>)}
						</>)}
                </>
			) : (
				<UserAuth
					email={email}
					setEmail={setEmail}
					password={password}
					setPassword={setPassword}
					handleLogin={handleLogin}
					handleSignUp={handleSignUp}
					hasAccount={hasAccount}
					setHasAccount={setHasAccount}
					emailError={emailError}
					passwordError={passwordError}
					name={name}
					setName={setName}
					number={number}
					setNumber={setNumber}
					nameError={nameError}
				/>
			)}
		</>
	);
}

export default SignIn;
