import React from 'react';
import CardItem from './CardItem';
import './Sponsors.css';

const Sponsors = () => {
  return (
    <>
        <div className="empty">
            <p className='sponsor--header'>Our Proud Sponsors</p>
        </div>

		<div class="client">
            <ul>
            <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Fsolidworks.jpg?alt=media&token=aa5c7d78-0d6e-428d-99a6-94e9fc64b429' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FAlligator%20logo_PNG%20(1).png?alt=media&token=e14b7379-4fe1-4497-be4d-d54eded9d398' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FPCM%20POWER.jpg?alt=media&token=7da311f0-6699-4963-b27c-599a1995f3c2' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Fdream%20polymer.png?alt=media&token=b056f2e5-53a0-43f1-b702-e9e05422a666' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Fbrose.jpg?alt=media&token=b3d0c322-adcb-43f4-8544-abf8c52650ec' alt="client2"/>
                </li>
                
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Favdut%20pcb.png?alt=media&token=73e753d1-9671-453b-afee-87efe53b19fe' alt="client2"/>
                </li>
            </ul>
	</div>

    <ul className='mlist'>
        <li>
            <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Fsolidworks.jpg?alt=media&token=aa5c7d78-0d6e-428d-99a6-94e9fc64b429' alt="client2" />
        </li>
        <li>
            <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FAlligator%20logo_PNG%20(1).png?alt=media&token=e14b7379-4fe1-4497-be4d-d54eded9d398' alt="client2" />
        </li>
        <li>
        <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FPCM%20POWER.jpg?alt=media&token=7da311f0-6699-4963-b27c-599a1995f3c2' alt="client2"/>
        </li>
        <li>
        <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Fdream%20polymer.png?alt=media&token=b056f2e5-53a0-43f1-b702-e9e05422a666' alt="client2"/>
       </li>
        <li>
        <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Fbrose.jpg?alt=media&token=b3d0c322-adcb-43f4-8544-abf8c52650ec' alt="client2"/>
       </li>
        <li>
        <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Favdut%20pcb.png?alt=media&token=73e753d1-9671-453b-afee-87efe53b19fe' alt="client2"/>
       </li>
    </ul>

    </>
  )
}

export default Sponsors;