import React from 'react';
import './Testimonials.css';

const Testimonials = () => {
    return (
        <>
        <div className="testi--containers">
            <div className="empty">
                <p className='testimonial--header'>What Do Our Mentors Say ?</p>
            </div>
            <div className="testimonial--section">
                <div className="testimonial--elements">
                    <div className="testimonial--card">
                        <p className='testimonial--data'>"The road to success in engineering is rarely smooth. Embrace the challenges, learn from your setbacks, and never give up on your dreams."</p>
                        <p className='testimonial--name'>Principal</p>
                        <p className='testimonial--desc'>Marathwada Mitra Mandal's College of Engineering</p>
                    </div>
                    <div className="testimonial--card">
                        <p className='testimonial--data'>"Remember, engineers are not just builders, they are storytellers. Every circuit tells a tale, every robot whispers a dream. So tell your story, through the language of electronics, and make the world listen."</p>
                        <p className='testimonial--name'>HOD, ETC Department</p>
                        <p className='testimonial--desc'>Marathwada Mitra Mandal's College of Engineering</p>
                    </div>
                </div>
            </div>   
        </div>         
        </>
    )
}

export default Testimonials;
