import React from 'react';
import {FaBars} from 'react-icons/fa';
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink} from './NavElements';
import './Navbar.css';

const Navbar = ({toggle}) => {
    function handleScroll() {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }

    return (
        <>
        <Nav>
            <NavbarContainer>
                <NavLogo to="/">
                    {/* <img src='images/rudra_icon.png' alt='Rudra' className='nav-img'></img> */}
                    <img src={require('./rudra_icon.png')} alt='Rudra' className='nav-img'></img>
                </NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars/>
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks to="home">Home</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="events">Events</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="history">History</NavLinks>
                    </NavItem>
                    {/* <NavItem>
                        <NavLinks to="portfolio">Portfolio</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="blog">Blog</NavLinks>
                    </NavItem> */}
                </NavMenu>
                <NavBtn>
                    <NavBtnLink to="contact" onClick={handleScroll}>Contact us</NavBtnLink>
                </NavBtn>
            </NavbarContainer>
        </Nav>
        </>
    );
};

export default Navbar;
