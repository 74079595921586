import {React} from 'react';
import { useParams } from "react-router";
// import database from '../../../fire';
// import { doc, getDoc } from "firebase/firestore";
import { FaClock, FaLinkedin, FaUser } from 'react-icons/fa';
import {BsCalendarDateFill} from 'react-icons/bs';
import {RiInstagramFill} from 'react-icons/ri';
import {GrMail} from 'react-icons/gr';


import './View.css';
import { CopyBlock, tomorrow, github, solarizedLight, paraisoLight } from 'react-code-blocks';
import { Link } from 'react-router-dom';

const View = () => {
    const { id } = useParams();
    // const [isValid, setValid] = useState(false);
    // const [url, setUrl] = useState('');
    // const [title, setTitle] = useState('');
    // const docRef = doc(database, "blog", id);
    console.log(id);

    const codeData = "void loop() {";
    // useEffect(() =>{
    //     getDoc(docRef).then(res =>{
    //         if(res.exists()){
    //             setValid(true);
    //             setUrl(res.data().src);
    //             setTitle(res.data().title);
    //         } else setValid(false);
    //     });
    // }, [docRef]);

    return (
        <>
        <div className="blog--container">
            <div className="blog--header">
                <div className="blog--author">
                    <p className='author--name'><FaUser className='p--icon'/> Iqbal Chacha</p>
                    <p className='creation--data'> <BsCalendarDateFill className='p--icon'/>23 January 2022 </p>
                    <p className='blog--time'><FaClock className='p--icon'/>15 min</p>
                </div>
                <p className='blog--title'>Introduction to Robotics for begineers</p>
                <p className='blog--para'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas itaque esse quisquam impedit nemo dicta et exercitationem aliquid commodi voluptates nam quam consequuntur delectus quos soluta ullam, maiores nesciunt a?Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error, doloremque. Error magni 
                </p>
                <div className="img--wrapper">
                    <img
                        src={require('./test.jpg')}
                        alt="login-svg"
                        className="banner--img"/>
                </div>
            </div>

            <div className="blog--data">
                <p className='section--name'>Introduction</p>
                <p className='blog--para'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas itaque esse quisquam impedit nemo dicta et exercitationem aliquid commodi voluptates nam quam consequuntur delectus quos soluta ullam, maiores nesciunt a?Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error, doloremque. Error magni ipsum possimus commodi in culpa maxime consequuntur, eligendi soluta expedita perferendis placeat officiis enim quasi voluptatem cupiditate pariatur.
                </p>
                <div className="img--wrapper">
                    <img
                        src='https://firebasestorage.googleapis.com/v0/b/zenith-website-9b337.appspot.com/o/images%2F1_0WWoMAwYGVKCfMmKysyBVw.jpeg?alt=media&token=3bdda036-abb9-4a61-93be-ced997ea5681'
                        alt="login-svg"
                        className="banner--img"/>
                </div>
            </div>

            <div className="blog--data">
                <p className='section--name'>Deep Dive into the Code</p>
                <p className='blog--para'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas itaque esse quisquam impedit nemo dicta et exercitationem aliquid commodi voluptates nam quam consequuntur delectus quos soluta ullam, maiores nesciunt a?Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error, doloremque. Error magni ipsum possimus commodi in culpa maxime consequuntur, eligendi soluta expedita perferendis placeat officiis enim quasi voluptatem cupiditate pariatur.
                </p>
                <CopyBlock
                    text = {codeData}
                    language = 'c'
                    showLineNumbers = {true}
                    startingLineNumbers = {1}
                    theme = {tomorrow}
                    codeBlock
                />
            </div>

            <div className="blog--data">
                <p className='section--name'>Conclusion</p>
                <p className='blog--para'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas itaque esse quisquam impedit nemo dicta et exercitationem aliquid commodi voluptates nam quam consequuntur delectus quos soluta ullam, maiores nesciunt a?Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error, doloremque. Error magni ipsum possimus commodi in culpa maxime consequuntur, eligendi soluta expedita perferendis placeat officiis enim quasi voluptatem cupiditate pariatur.
                </p>
                <div className="img--wrapper">
                    <img
                        src='https://firebasestorage.googleapis.com/v0/b/zenith-website-9b337.appspot.com/o/images%2F1_0WWoMAwYGVKCfMmKysyBVw.jpeg?alt=media&token=3bdda036-abb9-4a61-93be-ced997ea5681'
                        alt="login-svg"
                        className="banner--img"/>
                </div>
            </div>

            <div className="footer--container">
                {/* <p className='footer--topline'>REACH OUT TO US ON SOCIAL MEDIA </p> */}
                <div className="contact--footer">
                        <a href='https://www.instagram.com/robocon_rudra/' target='blank'>
                            <p className='insta--logo'><RiInstagramFill className='p--icon--f'/>Instagram</p>
                        </a>
                        <a href='https://www.linkedin.com/company/robocon-team-rudra/mycompany/' target='blank'>
                            <p className='linkedin--logo'><FaLinkedin className='p--icon--f'/>Linked In </p>
                        </a>
                        <Link to='/contact'>
                            <p className='message--us'><GrMail className='p--icon--f'/>contact@teamrudra.com</p>
                        </Link>
                </div>
            </div>

        </div>
        <div className="blog--suggestions">
                blogs of same genre
        </div>
        </>
    )
}

export default View;
