import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import {Link as LinkRoute} from 'react-router-dom';

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({isOpen}) => (isOpen ? '0' : '-100%')};
`

export const CloseIcon = styled(FaTimes)`
   color: #000000;
`

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`
export const SidebarWrapper = styled.div`
  color: #fff;
`
export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(6, 80px);
  text-align: center;

  @media screen and (max-width: 480px) {
      grid-template-rows: repeat(6, 80px);
  }
  `

export const SidebarLink = styled(LinkRoute)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  transition: 0.2 ease-in-out;
  color: #000000;
  cursor: pointer;

  &:hover{
      color: #DC1D28;
      transition: 0.2 ease-in-out;
  }
`
export const SideBtnWrap = styled.div`
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  width: 50%;
  text-decoration: none;
`

// export const SideBtnWrap = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-left: 36px;
// `
export const SidebarRoute = styled(LinkRoute)`
  border-radius: 50px;
  /* justify-self: center; */
  white-space: nowrap;
  background: #000000;
  padding: 16px 64px;
  color: #ffffff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  /* text-decoration: none;
  text-align: center; */
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #212020;
    color: #ffffff;
  }
`
