import React from 'react'
import {SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SideBtnWrap, SidebarLink, SidebarRoute} from './SidebarElements';
import './Sidebar.css'

const Sidebar = ({isOpen, toggle}) => {
    function handleScroll() {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='home' onClick={toggle}>
                        Home
                    </SidebarLink>
                    <SidebarLink to='events' onClick={toggle}>
                        Events
                    </SidebarLink>
                    <SidebarLink to='history' onClick={toggle}>
                        History
                    </SidebarLink>
                    {/* <SidebarLink to='portfolio' onClick={toggle}>
                        Portfolio
                    </SidebarLink>
                    <SidebarLink to='blog' onClick={toggle}>
                        Blog
                    </SidebarLink>                     */}
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to='home' onClick={handleScroll}>Contact us</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    );
}

export default Sidebar;