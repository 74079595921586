import {React, useState} from 'react';
import Button from '../Button/Button';
import { Link } from "react-router-dom";
import database from '../../fire';
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword} from "firebase/auth";


import './SuperAdmin.css';

const SuperAdmin = (props) => {
    const [addCreator, setAddCreator] = useState(false);
    const [addAdmin, setAddAdmin] = useState(false);
    const [addHistory, setAddHistory] = useState(false);
    const [addEvent, setAddEvent] = useState(false);
    const [addTeammate, setteamCreator] = useState(false);
    const [eventID, setID] = useState(0);

    const [transfer, setTransfer] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [role, setRole] = useState('');
    const [contact, setContact] = useState('');

    const [tName, setTName] = useState('');
    const [tBranch, setTBranch] = useState('');
    const [tYear, setTYear] = useState('');

    const [topline, setTopline] = useState('');
    const [headline, setHeadline] = useState('');
    const [description, setDescription] = useState('');
    const [robot1, setRobot1] = useState('');
    const [robot2, setRobot2] = useState('');
    const [team_photo, setTeamPhoto] = useState('');
    const [other_photo, setOtherPhoto] = useState('');
    const [robot_photo, setRobotPhoto] = useState('');
    const [logo, setLogo] = useState('');
    const [year, setYear] = useState('');
    const [acheivements, setAcheivements] = useState('');
    const [youtube, setYoutube] = useState('');
    const [abtRob, setAbvRob] = useState('');

    const auth = getAuth();

	const {handleLogout} = props;    

    const showAddCreator = () =>{
        setAddCreator(!addCreator);
        setAddAdmin(false);
        setTransfer(false);
    }

    const showAddAdmin = () =>{
        setAddAdmin(!addAdmin);
        setAddCreator(false);
        setTransfer(false);
    }

    const showTransfer = () => {
        setTransfer(!transfer);
        setAddAdmin(false);
        setAddCreator(false);
    }
    const teamateCreator = ()=>{

        setteamCreator(!addTeammate);
    }

    const teamCreator = () =>{
        const docRef = doc(database, "history", year.trim());
        getDoc(docRef).then(res =>{
            if(res.exists()){
                console.log("Document data:", res.data());
                var list = res.data().team_list;
                list.push({Name: tName.trim(), Branch: tBranch.trim(), Year: tYear.trim()})
                updateDoc(docRef,{
                    "team_list": list
                }).then(setTName(' '));
            } else console.log("No such document!");
        });
    }


    const historyCreator = () =>{
        setAddHistory(!addHistory);
    }

    const eventCreator = () => {
        setAddEvent(!addEvent);
    }

    const handleHistory = ()=>{
        setDoc(doc(database, "history", year), {
            darkText: true,
            robots: abtRob.trim(),
            headline: headline.trim(),
            topLine: topline.trim(),
            buttonLabel2: "Watch Video",
            other_img: other_photo.trim(),
            lighText: false,
            imgStart: false,
            robot2: robot2.trim(),
            description: description.trim(),
            primary: true,
            img: logo.trim(),
            ishistory: true,
            alt: "Banner-Element",
            robot1: robot1.trim(),
            dark: true,
            linkto1: "/History",
            linkto2: youtube.trim(),
            lightTextdesc: false,
            team: team_photo.trim(),
            buttonLabel: "Check out our Robots",
            lightBg: true,
            team_list: [
                {
                    "Year": "Final Year",
                    "Name": "Vrutik Velani",
                    "Branch": "Mechanical Engineering"
                }],
            robot: robot_photo.trim(),
            id: year.trim(),
            acheive: acheivements.trim()
        }).then(() => {
            setTopline('');
            setAbvRob('');
            setAcheivements('');
            setRobot1('');
            setRobot2('');
            setTeamPhoto('');
            setRobotPhoto('');
            setYear('')
            setYoutube('')
            setOtherPhoto('');
            setDescription('');
            setHeadline('');
        });
    }

    const handleEvent = () => {
        setDoc(doc(database, "events", eventID.trim()), {
            darkText: true,
            headline: headline.trim(),
            topLine: topline.trim(),
            lighText: false,
            imgStart: false,
            description: description.trim(),
            primary: true,
            img: logo.trim(),
            alt: "Banner-Element",
            dark: true,
            linkto1: "/History",
            linkto2: youtube.trim(),
            lightTextdesc: false,
            buttonLabel: "Check out our Robots",
            lightBg: true,
            id: eventID.trim(),
        }).then(() => {
            setTopline('');
            setYear('')
            setYoutube('')
            setDescription('');
            setHeadline('');
        });
    }

    const handleCreator = () =>{
        // if(role !== '' && name !== '' && surname !== ''){
            createUserWithEmailAndPassword(auth, email, password).then((userCreds) =>{
                // setDoc(doc(database, "users", userCreds.user.uid), {
                //     name: name,
                //     surname: surname,
                //     contact: contact,
                //     role: role,
                //     uid: userCreds.id
                // }).then(console.log(userCreds.user.uid));
            }).catch((err) =>{
                switch (err.code) {
                    case "auth/email-already-in-use":
                    case "auth/invalid-email":
                        setEmailError(err.message);
                        break;
                    case "auth/weak-password":
                        setPasswordError(err.message);
                        break;
                    default:
                        break;
                }            
            })
        // }
        console.log("Created A creator");
    }

    const handleAdmin = () =>{
        console.log("Created A new Admin");
    }

    const handleTransfer = () =>{
        console.log("Handle Privilage transfer");
    }

  return(
    <>
        <div className="super--banner">
            <h1>Welcome Back Super Admin</h1>
            <Button buttonSize='btn--wide' buttonColor='red' onClick={showAddCreator}>
                Add A  New Creator
            </Button>
            <Button buttonSize='btn--wide' buttonColor='red' onClick={showAddAdmin}>
                Add A  New Admin
            </Button>
            <Button buttonSize='btn--wide' buttonColor='red' onClick={showTransfer}>
                Transfer Super Admin Previlages
            </Button>
            <Button buttonSize='btn--wide' buttonColor='red' onClick={handleLogout}>
                Logout
            </Button>
            <Button buttonSize='btn--wide' buttonColor='red' onClick={teamateCreator}>
                Add Teammate
            </Button>
            <Button buttonSize='btn--wide' buttonColor='red' onClick={eventCreator}>
                Add An Event
            </Button>
              <Button buttonSize='btn--wide' buttonColor='red' onClick={historyCreator}>
                Add History
            </Button>
        </div>

{/* Event Section */}
          {addEvent ? (<>
              <section className="login">
                  <div className="loginContainer">
                      <h1>Add Event</h1>
                      <img
                          src="images/add_creator.svg"
                          alt="creator-svg"
                          className="auth__svg-img"
                      />
                      <label className="field">
                          <input
                              type="text"
                              autoFocus
                              required
                              value={eventID}
                              onChange={(e) => setID(e.target.value)}
                          />
                          <span className="placeholder">ID: 1(Evnet Number)</span>
                      </label>
                      <label className="field">
                          <input
                              type="text"
                              autoFocus
                              required
                              value={topline}
                              onChange={(e) => setTopline(e.target.value)}
                          />
                          <span className="placeholder">Topline eg: January 2022</span>
                      </label>
                      <label className="field">
                          <input
                              type="text"
                              autoFocus
                              required
                              value={headline}
                              onChange={(e) => setHeadline(e.target.value)}
                          />
                          <span className="placeholder">Headline eg: Sharing the Knowledge</span>
                      </label>
                      <label className="field">
                          <input
                              type="text"
                              autoFocus
                              required
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                          />
                          <span className="placeholder">Description (short)</span>
                      </label>

                      <label className="field">
                          <input
                              type="text"
                              required
                              value={logo}
                              onChange={(e) => setLogo(e.target.value)}
                          />
                          <span className="placeholder">Banner Photo Link</span>
                      </label>
                      <label className="field">
                          <input
                              type="text"
                              required
                              value={youtube}
                              onChange={(e) => setYoutube(e.target.value)}
                          />
                          <span className="placeholder">Report Link</span>
                      </label>

                      <div className="btnContainer">
                          <button
                              className="btn--userAuth"
                              onClick={handleEvent}
                          >
                              Add Event
                          </button>
                      </div>
                  </div>
              </section>
          </>) : (<></>)}


{/* Team mate section */}
        { addTeammate ? (<> 
        <section className="login">
                    <div className="loginContainer">
                        <img
                            src="images/add_creator.svg"
                            alt="creator-svg"
                            className="auth__svg-img"
                        />

                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={tName}
                                onChange={(e) => setTName(e.target.value)}
                            />
                            <span className="placeholder">Name</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={tBranch}
                                onChange={(e) => setTBranch(e.target.value)}
                            />
                            <span className="placeholder">Branch</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={tYear}
                                onChange={(e) => setTYear(e.target.value)}
                            />
                            <span className="placeholder">Year eg: Second Year</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                            />
                            <span className="placeholder">Year</span>
                        </label>
                        <div className="btnContainer">
                            <button
                                className="btn--userAuth"
                                onClick={teamCreator}
                            >
                                Add Teammate
                            </button>
                        </div>

                    </div>
			</section> 
            </>) : (<></>)}

            {/* Add History */}
          {addHistory ? (<>
            <section className="login">
                    <div className="loginContainer">
                        <h1>Add History</h1>
                        <img
                            src="images/add_creator.svg"
                            alt="creator-svg"
                            className="auth__svg-img"
                        />

                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={topline}
                                onChange={(e) => setTopline(e.target.value)}
                            />
                            <span className="placeholder">Topline eg: Robocon 2022</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={headline}
                                onChange={(e) => setHeadline(e.target.value)}
                            />
                            <span className="placeholder">Headline eg: Sharing the Knowledge</span>
                        </label>                        
                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <span className="placeholder">Description (short)</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={robot1}
                                onChange={(e) => setRobot1(e.target.value)}
                            />
                            <span className="placeholder">About Robot 1</span>
                        </label>

                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={robot2}
                                onChange={(e) => setRobot2(e.target.value)}
                            />
                            <span className="placeholder">About Robot 2</span>
                        </label>

                        <label className="field">
                            <input
                                type="text"
                                required
                                value={team_photo}
                                onChange={(e) => setTeamPhoto(e.target.value)}
                            />
                            <span className="placeholder">Team Photo Link</span>
                        </label>

                        <label className="field">
                            <input
                                type="text"
                                required
                                value={other_photo}
                                onChange={(e) => setOtherPhoto(e.target.value)}
                            />
                            <span className="placeholder">Extra Photo Link</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                required
                                value={robot_photo}
                                onChange={(e) => setRobotPhoto(e.target.value)}
                            />
                            <span className="placeholder">Robot Photo Link</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                required
                                value={logo}
                                onChange={(e) => setLogo(e.target.value)}
                            />
                            <span className="placeholder">Theme Logo Link</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                required
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                            />
                            <span className="placeholder">Year</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                required
                                value={acheivements}
                                onChange={(e) => setAcheivements(e.target.value)}
                            />
                            <span className="placeholder">Acheivements</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                required
                                value={youtube}
                                onChange={(e) => setYoutube(e.target.value)}
                            />
                            <span className="placeholder">Journey Video Link</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                required
                                value={abtRob}
                                onChange={(e) => setAbvRob(e.target.value)}
                            />
                            <span className="placeholder">About Robots</span>
                        </label>

                        <div className="btnContainer">
                            <button
                                className="btn--userAuth"
                                onClick={handleHistory}
                            >
                                Add History
                            </button>
                        </div>
                    </div>
			</section>
          </>) : (<></>)}


        {addCreator ? (<>
            <div className="add--creator">
                <section className="login">
                    <div className="loginContainer">
                        <img
                            src="images/add_creator.svg"
                            alt="creator-svg"
                            className="auth__svg-img"
                        />

                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <span className="placeholder">Name</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                            />
                            <span className="placeholder">Surname</span>
                        </label>                        
                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                            />
                            <span className="placeholder">Role</span>
                        </label>
                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={contact}
                                onChange={(e) => setContact(e.target.value)}
                            />
                            <span className="placeholder">Contact</span>
                        </label>

                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <span className="placeholder">Email-ID</span>
                        </label>
                        <p className={`${emailError ? "errorMsg" : "noMsg"}`}>
                            {emailError}
                        </p>

                        <label className="field">
                            <input
                                type="password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="placeholder">Password</span>
                        </label>

                        <p className="errorMsg">{passwordError}</p>

                        <div className="btnContainer">
                            <button
                                className="btn--userAuth"
                                onClick={handleCreator}
                            >
                                Add Creator
                            </button>
                        </div>
                    </div>
			</section>


            </div></>) : (<></>)}
        {addAdmin ? (<>
            <div className="add--admin">
                <section className="login">
                    <div className="loginContainer">
                        <img
                            src="images/add_admin.svg"
                            alt="admin-svg"
                            className="auth__svg-img"
                        />
                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <span className="placeholder">Email-ID</span>
                        </label>
                        <p className={`${emailError ? "errorMsg" : "noMsg"}`}>
                            {emailError}
                        </p>

                        <label className="field">
                            <input
                                type="password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="placeholder">Password</span>
                        </label>

                        <p className="errorMsg">{passwordError}</p>

                        <div className="btnContainer">
                            <button
                                className="btn--userAuth"
                                onClick={handleAdmin}
                            >
                                Add Admin
                            </button>
                        </div>
                    </div>
			</section>
            </div></>) : (<></>)}
        {transfer ? (<>
            <div className="transfer--super">
            <section className="login">
                    <div className="loginContainer">
                        <img
                            src="images/super_transfer.svg"
                            alt="transfer-svg"
                            className="auth__svg-img"
                        />
                        <label className="field">
                            <input
                                type="text"
                                autoFocus
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <span className="placeholder">Email-ID</span>
                        </label>
                        <p className={`${emailError ? "errorMsg" : "noMsg"}`}>
                            {emailError}
                        </p>

                        <label className="field">
                            <input
                                type="password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="placeholder">Password</span>
                        </label>

                        <p className="errorMsg">{passwordError}</p>

                        <div className="btnContainer">
                            <button
                                className="btn--userAuth"
                                onClick={handleTransfer}
                            >
                                Transfer Previlages
                            </button>
                        </div>
                    </div>
			</section>
            </div></>) : (<></>)}
    </>
  );
};

export default SuperAdmin;
