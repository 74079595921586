import { useState } from 'react';
import { Routes, Route} from "react-router-dom";
import Navbar from './components/Navbar/Navbar';
import './App.css';
import Sidebar from './components/Sidebar/Sidebar';
import Home from './components/Home/Home';
import Banner from './components/Home/Banner/Banner';
import Blog from './components/Blog/Blog';
import View from './components/Blog/View/View';
import History from './components/History/History';
import SignIn from './components/SignIn/SignIn';
import IndHist from './components/History/IndHist';
import Footer from './components/Footer/Footer';
import Events from './components/Events/Events';

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
    <Sidebar isOpen={isOpen} toggle={toggle}/>
    <Navbar toggle={toggle}/>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/blog" element={<Blog/>}></Route>
        <Route path="/blog/:id" element={<View/>}></Route>
        <Route path="/test" element={<Banner/>}></Route>
        <Route path="/history" element={<History/>}></Route>
        <Route path="/history/:id" element={<IndHist/>}></Route>
        <Route path="/events" element={<Events />}></Route>
        {/* <Route path="/events/:id" element={<Events />}></Route> */}
        <Route path="/signin" element={<SignIn/>}></Route>
        <Route path="*" element={<Home/>}></Route>
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
