import {React, useState, useEffect} from 'react';
import { useParams } from "react-router";
import database from '../../fire';
import { doc, getDoc } from "firebase/firestore";
import './IndHist.css';

const IndHist = () => {
    const { id } = useParams();
    const [isValid, setValid] = useState(true);
    const [url, setUrl] = useState('');
    const [imgPath, setPath] = useState('');
    const [title, setTitle] = useState('');
    const [file, setFile] = useState('');
    const [dispData, setData] = useState({});
    const docRef = doc(database, "history", id);

    const [datta, setDatta] = useState([{}]);
    const [rowss, setRows] = useState([]);
    const [colss, setCols] = useState([]);

    useEffect(() =>{
        getDoc(docRef).then(res =>{
            if(res.exists()){
                setValid(true);
                setUrl(res.data().src);
                setTitle(res.data().title);
                setData(res.data());
                setDatta(res.data().team_list);
                console.log(res.data());
            } else setValid(false);
        });

        // const result = rows.map((row) => {
        // const vals = row.split(',');
        // return vals.reduce((res, val, idx) => {
        //     const prop = cols[idx];
        //     res[prop] = val;
        //     return res;
        // }, {});
        // });
    }, []);

    return(
        <>
        <div className="motha">
         <div className="blog--container">
         <iframe height="500" src={dispData.linkto2} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <div className="blog--header">
                {/* <img
                    src={dispData.img}
                    alt="login-svg"
                    className="logo--img"/> */}
                <p className='blog--title'>{dispData.headline}</p>
                <p className='blog--para'>
                    {dispData.description}
                </p>
                <img
                        src={dispData.robot}
                        alt="login-svg"
                        className="robot--img"/>
                <h2 className='blog--subtitle'>Robot 1</h2>
                <p className='blog--para'>
                    {dispData.robot1}
                </p>
                <h2 className='blog--subtitle'>Robot 2</h2>
                <p className='blog--para'>
                    {dispData.robot2}
                </p>

                <img
                        src={dispData.other_img}
                        alt="login-svg"
                        className="robot--img"/>                
                <h2 className='blog--subtitle'>Acheivements</h2>
                <p className='blog--para'>
                    {dispData.acheive}
                </p>

                <div className="img--wrapper">
                    <img
                        src={dispData.team}
                        alt="login-svg"
                        className="banner--img"/>
                </div>
                <h2 className='blog--subtitle--head'>Team Rudra {id}</h2>
                {datta.length ? (
                    <>
                    <table className='test--table'>
                        <tr>
                            <th>Name</th>
                            <th>Branch</th>
                            <th>Year</th>
                        </tr>
                    {datta.map(e =>
                        <tr>
                            <td>{e.Name}</td>
                            <td>{e.Branch}</td>
                            <td>{e.Year}</td>
                        </tr>
                    )}
                    </table>
                    </>): (<></>)}

            </div>
        </div>
        </div>
        </>
        );
};

export default IndHist;
