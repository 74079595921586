import {React, useEffect, useState} from 'react';


function CardItem(props) {

    const handleCart = () => {
        console.log("Handle");
    }

    return (
        <>
        <li className='cards__item'>
                    <img
                        className='cards__item__img'
                        alt='Travel'
                        src={props.src}
                    />
                    <div className='cards__item__info'>
                        <h2 className='cards__item__text'><b>Aligator Automation</b></h2>
                    </div>
            {/* <a className='cards__item__link' >
                <figure className='cards__item__pic-wrap' data-category="{props.label}">
                    <img
                        className='cards__item__img'
                        alt='Travel'
                        src={props.src}
                    />
                </figure>
                <div className='cards__item__info'>
                      <h2 className='cards__item__text'><b>Aligator Automation</b></h2>
                </div>
            </a> */}
        </li>
        </>
    );
};

export default CardItem;

