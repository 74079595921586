import React from 'react';

const Creator = () => {
  return (
      <>
      Welcome Creator
      </>
  );
};

export default Creator;
