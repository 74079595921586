import React from 'react';
import './StageInfo.css';

const StageInfo = () => {
    return (
        <>
        
        <div className="info--elements">
            <div className="info--data">
                <p className='info--topline'>WHAT IS ABU-ROBOCON?</p>
                <p className='info--p'>ABU-Robocon is an<br/> International Robot Contest</p>
                <p className='info--p2'>
                    hosted by Asia-Pacific Broadcasting Union. 
                    DD Robocon the national competition takes place in 3 rounds with over 80 participating teams from all over India.
                </p>
            </div>
            <div className="info--vector">
                <img src='images/arrow.svg' alt='vector' className='arrow--vector'></img>
            </div>
        </div>            
        </>
    )
}

export default StageInfo;
