import React from 'react';
import './Stages.css';

const Stages = () => {
    return (
        <>
        <div className="stage--element">
            <div className="stage--card">
                    <img src='images/1.svg' alt='1'></img>
                    <div className="card--parent">
                        <div className="card--data">
                            <p className='card--header'>Stage 1</p>
                            <p className='stage--data'>Design Detailed Document</p>
                        </div>
                    </div>
                </div>            

            <div className="stage--card">
                <img src='images/2.svg' alt='2'></img>
                <div className="card--parent">
                    <div className="card--data">
                        <p className='card--header'>Stage 2</p>
                        <p className='stage--data'>Robot Working Video Demonstration</p>
                    </div>
                </div>
            </div>

            <div className="stage--card">
                <img src='images/3.svg' alt='3'></img>
                <div className="card--parent">
                    <div className="card--data">
                        <p className='card--header'>Stage 3</p>
                        <p className='stage--data'>Final One on One Match</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Stages;
