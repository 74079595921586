import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import "./UserAuth.css";

const UserAuth = (props) => {
	const [hasAccount, setHasAccount] = useState(true);
	const [forgot_pass, setPass] = useState(false);
	const {
		email,
		setEmail,
		password,
		setPassword,
		handleLogin,
		emailError,
		passwordError,
		handleSignUp,
		name,
		setName,
		number,
		setNumber,
		nameError
	} = props;

	return (
		<>
			<section className="login">
				<div className="loginContainer">
                    <img
                        src="images/login.svg"
                        alt="login-svg"
                        className="auth__svg-img"
                    />
					<label className="field">
						<input
							type="text"
							autoFocus
							required
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<span className="placeholder">Email-ID</span>
					</label>
					<p className={`${emailError ? "errorMsg" : "noMsg"}`}>
						{emailError}
					</p>

					<label className="field">
						<input
							type="password"
							required
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<span className="placeholder">Password</span>
					</label>

					<p className="errorMsg">{passwordError}</p>

					<div className="btnContainer">
                        <button
                            className="btn--userAuth"
                            onClick={handleLogin}
                        >
                            Sign In
                        </button>
                        <p>
                            <Link to="/forgotpassword">
                                <span>
                                    Forgot Password ?
                                </span>
                            </Link>
                        </p>
					</div>
				</div>
			</section>
		</>
	);
};

export default UserAuth;
