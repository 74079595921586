import React from 'react';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import '../Button/Button.css';

import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, ImgWrap, TextWrapper, TopLine, Heading, Subtitle, Img } from './EventElements';
// import './info.css';


const EventSection = ({ lightBg, id, imgStart, topLine, lightText, headline, darkText, description, img, alt, dark, buttonLabel, buttonLabel2, linkto1, linkto2, ishistory }) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper >
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Subtitle darkText={darkText}>{description}</Subtitle>
                                <a href={linkto2} target='_blank' rel='noreferrer'>
                                    <Button buttonSize='btn--wide' buttonColor='red'>
                                        Read Report
                                    </Button>
                                </a>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                                {/* <Img src='images/beta_seminar.png' alt={alt} /> */}
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>

        </>
    );
}

export default EventSection;
