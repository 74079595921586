import React, { useEffect, useState } from 'react';
import EventSection from '../EventSection';
import database from '../../fire';
import { collection, getDocs} from "firebase/firestore";

import './Events.css';

const Events = () => {
    const collectionRef = collection(database, "events");
    const [info, setInfo] = useState([{}]);
    const [hasData, setHasdata] = useState(false);

    useEffect(() =>{
        getDocs(collectionRef).then((res) =>{
                res.forEach(element =>{
                    const temp = info;
                    temp.push(element.data());
                    setInfo(temp);
                })
                const test = info;
                test.shift();
                test.reverse();
                setInfo(test);
                setHasdata(true);
        });
    });

    return (
        <>
            { hasData ? (
                <>
                {info.map(res =>
                    <EventSection {...res}/>
                )}
                </>
            )
            : (<>Loading.....</>)}
        </>
    )
}

export default Events;
